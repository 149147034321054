<template>
  <div class="searchOrder">
    <div class="list-title">Import products</div>
    <div class="sample">
      <a-space>
        <div class="top-title">Sample File</div>
        <a-button type="primary" shape="round">
          <a style="text-decoration:none;" :href="ProductSampleUrl + '?' + Math.random()"
            :download="'ProductSampleImperial_' + productVersion + '.xlsx'">
            <icon-download />Download
          </a>
        </a-button>
      </a-space>
    </div>
    <div class="sample-tips">
      1. Only support <span class="mark">IMPERIAL ( inch | lb | oz );</span><br />
      2. Our sample file version is <span class="mark">{{ productVersion }}.</span> Download new sample file if
      yours was downloaded earlier, Otherwise system won't recognize the format.<br />
      3. Make sure the file in the same format of downloaded file.<br />
      4. Do not change the file extension and format. It has to be a <span class="mark"> xlsx file.</span> <br />
      5. File name can only contain <span class="mark">letters, numbers, hyphens and underscores.</span><br />
      6. Uploading will overwrite the existing product if same SKU occurs.
    </div>
    <div class="import">
      <a-card :style="{ width: '660px' }" title="Import">
        <a-config-provider :locale="enUS">
          <a-upload draggable :custom-request="customRequest" @before-upload="beforeUpload" tip="Only xlsx file can be Imported,
    and file name can only contain letters, numbers, hyphens and underscores." multiple accept=".xlsx" />
        </a-config-provider>
      </a-card>
    </div>
    <div class="top-title record">Imported Records</div>
    <!-- 表格 -->
    <a-config-provider :locale="enUS">
      <a-table :columns="listColumns" :data="listData.value" :bordered="false" :pagination="false"
        style="margin-bottom:24px;">
        <template #empty>
          <div v-if="listData.value.length === 0" class="empty-cont">
            <img src="../../../assets/images/empty/listempty.svg" alt="">
            <div>No results found</div>
          </div>
        </template>
        <template #filename="{ record }">
          <a-button type="text">
            <a :href="record.upload_filename_url + '?' + Math.random()" class="upload-link">
              {{ record.filename }}
              <icon-to-bottom class="downLoad-icon" />
            </a>
          </a-button>
        </template>
        <template #error="{ record }">
          <a-button type="text" :disabled="record.error === 0">
            <a :href="adslink + record.error_filename_url + '?' + Math.random()" :download="record.error_filename"
              v-if="record.error !== 0" class="upload-link">
              {{ record.error }}
            </a>
            <span v-else>{{ record.error }}</span>
          </a-button>
        </template>
        <template #action="{ record }">
          <a-tooltip content="Detail">
            <a-button shape="circle" @click="goDetail(record.key)">
              <icon-right />
            </a-button>
          </a-tooltip>
        </template>
      </a-table>
    </a-config-provider>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import {
  IconRight, IconToBottom, IconDownload
} from '@arco-design/web-vue/es/icon'
import { Modal, Notification } from '@arco-design/web-vue'
import local from '@/utils/local.js'
import { getLastFiveProductImport } from '@/api/product.js'
const adslink = process.env.VUE_APP_ADSLINK
const customRequest = (option) => {
  const { onProgress, onError, onSuccess, fileItem, name } = option
  const xhr = new XMLHttpRequest()
  if (xhr.upload) {
    xhr.upload.onprogress = function (event) {
      let percent;
      if (event.total > 0) {
        // 0 ~ 1
        percent = event.loaded / event.total;
      }
      onProgress(percent, event);
    };
  }
  xhr.onload = function onload () {
    let msg = JSON.parse(xhr.response)
    if (msg.data.success === 0) {
      Notification.error({
        title: 'Upload Error!',
        content: () => {
          const node = msg.error.map(m => (<div>{m}</div>))
          return node
        }
      })
      return onError()
    } else {
      onSuccess(xhr.response)
    }
  }

  const formData = new FormData()
  const token = local.get('token')
  formData.append('files[]', fileItem.file)
  xhr.open('post', adslink + '/api/merchant/productImport', true);
  xhr.setRequestHeader("Authorization", 'Bearer ' + token)
  xhr.send(formData);
}
const beforeUpload = (file) => {
  const h = file.name.substring(file.name.lastIndexOf('.') + 1)
  return new Promise((resolve, reject) => {
    if (h === 'xlsx' || h === 'csv') {
      Modal.confirm({
        title: 'Confirm Upload',
        content: `Are you sure to upload ${file.name}`,
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => resolve(true)
      })
    } else {
      Notification.error({
        title: 'Upload Error',
        content: 'Sorry, only csv/xlsx files are allowed.'
      })
    }
  })
}
const router = useRouter()
const listColumns = [
  {
    title: 'No.',
    dataIndex: 'no',
    slotName: 'no'
  },
  {
    title: 'Filename',
    dataIndex: 'filename',
    slotName: 'filename'
  },
  {
    title: 'Imported At',
    dataIndex: 'importat'
  },
  {
    title: 'Total',
    dataIndex: 'total'
  },
  {
    title: 'Imported',
    dataIndex: 'imported'
  },
  {
    title: 'Error',
    dataIndex: 'error',
    slotName: 'error'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action'
  }
]
// 表格数据
let listData = reactive({ value: [] })
const getList = async () => {
  const msg = await getLastFiveProductImport({})
  if (msg.code === 0) {
    let info = msg.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({ key: info[i].upload_id, no: i + 1, status: info[i].status, importat: info[i].created_at, filename: info[i].upload_filename, total: info[i].include_products, imported: info[i].success_products, error: info[i].error_products, errCase: info[i].error_msg, error_filename_url: info[i].error_filename_url, error_filename: info[i].error_filename_name, upload_filename_url: info[i].upload_filename_url })
    }
    listData.value = currentData
  } else if (msg.code === 2) {
    // 关闭定时器
    clearInterval(timer.value)
  }
}
// 定时器
const timer = ref(null)
timer.value = setInterval(() => {
  getList()
}, 3000)
onBeforeRouteLeave(() => {
  // 关闭定时器
  clearInterval(timer.value)
})
function goDetail (a) {
  let routerUrl = router.resolve({ path: '/products/importpro/importdetail', query: { upload: a } })
  window.open(routerUrl.href, '_blank')
}
const productVersion = JSON.parse(local.get('configInfo')).product_sample_version
const ProductSampleUrl = JSON.parse(local.get('configInfo')).product_sample_url
</script>
<style lang="less" scoped>
.searchOrder {
  padding: 0 24px;

  .list-title {
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text-1);
    margin: 18px 0 18px 0;
  }

  .sample {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-border-2);
  }

  .sample-tips {
    margin: 16px 16px 42px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-3);

    .mark {
      color: var(--color-text-1);
    }
  }

  .import {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top-title {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .record {
    padding: 20px 0;
  }
}

.empty-cont {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.downLoad-icon {
  color: rgb(var(--primary-6));
  cursor: pointer;
}

.upload-link {
  text-decoration: none;
  color: var(--color-text-1) !important;
}
</style>
